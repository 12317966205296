<template>
  <div>
    <van-nav-bar
      title="情绪日记"
      left-text=""
      left-arrow
      class="navbar"
      @click-left="goBack"
    />
    <div class="diary">
      <!-- 今日天气 -->
      <div class="weather">
        <div class="ctx-title">
          <div class="title-ctx">今日天气</div>
          <div class="title-fk"></div>
        </div>

        <div class="tianqi">
          <div
            v-for="(weather, index) in weatherItems"
            :key="index"
            :class="['tianqiitem', { tianqiitemactive: index === activeIndex }]"
            @click="handleClick(index)"
          >
            <img class="img" :src="weather.imageSrc" :alt="weather.alt" />
            <div>{{ weather.text }}</div>
          </div>
        </div>
      </div>

      <!-- 情绪坐标 -->

      <div class="coordinates">
        <div class="ctx-title">
          <div class="title-ctx">今日情绪坐标</div>
          <div class="title-fk"></div>
        </div>
        <div class="zuobiao" ref="zuobiaoBox" @click="toggleStar($event)">
          <!-- zuobiao盒子的现有内容 -->
          <div class="dot-container">
            <!-- 在这里添加五角星 -->
            <img
              v-if="showStar"
              ref="starElement"
              class="star"
              src="https://lesson.iapeap.com/images/EmotionalValue/wujiaoxing.png"
              alt="五角星"
              width="14"
              height="14"
              :style="{
                left: starX + 'px',
                top: starY + 'px',
                position: 'relative',
              }"
            />
          </div>
        </div>
        <div class="wenxintishi">
          <div class="qingxuzuobiao">
            <img
              src="https://lesson.iapeap.com/images/EmotionalValue/gantanhao.png"
              alt=""
            />
            <div>温馨提示：</div>
          </div>
          <div class="wenxintishi-ctx2">
            1.你可以根据自己“愉快”或“不愉快”的感受，以及体验到的情绪强度来选择自己的情绪坐标
          </div>
          <div class="wenxintishi-ctx2">
            ⒉你也可以参考图中一些常见情绪的“坐标”
          </div>
          <div class="wenxintishi-ctx2">
            3.你可以使用这个情绪坐标作为记录和觉察自己情绪的工具，在7天之后看到自己这一周情绪的“坐标分布”，了解自己的情绪状态
          </div>
        </div>
      </div>

      <!-- 今日体验 -->
      <div class="jinritiyan">
        <div class="ctx-title">
          <div class="title-ctx">今日体验</div>
          <div class="title-fk"></div>
        </div>
        <div v-html="formattedText"></div>
        <img
          class="shurukuang"
          src="https://lesson.iapeap.com/images/EmotionalValue/shurukuang.png"
          alt=""
        />
        <textarea
          class="tiyanbianji"
          name=""
          id=""
          cols="30"
          rows="10"
          v-model="comment"
        ></textarea>

        <van-uploader v-model="fileList" multiple :after-read="afterRead">
          <div class="tianjia">+</div>
        </van-uploader>
      </div>

      <div style="margin-top: 10px">
        <van-checkbox v-model="onlyMe" shape="square"
          ><span
            style="
              height: 14px;
              font-size: 10px;
              font-weight: 500;
              color: #629ee9;
              line-height: 14px;
              letter-spacing: 1px;
            "
            >仅自己可见</span
          ></van-checkbox
        >
      </div>
      <div class="diary-btn">
        <button @click="getsaveEmoDiary">提交</button>
      </div>
    </div>
    <div class="wancheng" v-if="wcshow">
      <div class="wcbox">
        <img
          src="https://lesson.iapeap.com/images/EmotionalValue/langou.png"
          alt=""
        />

        <div>提交成功</div>
        <button v-if="days == 7" @click="tosevenDay">查看七天坐标</button>
        <button @click="goBack">返回练习</button>
      </div>
    </div>
  </div>
</template>

<script>
import { Toast } from "vant";
import {
  uploadFile,
  saveEmoDiary,
  setComplated,
  memberLog,
} from "../../api/login";
export default {
  data() {
    return {
      id: "0",
      wcshow: false,
      days: window.localStorage.getItem("days"),
      fileList: [],
      imglist: [],
      showStar: false, // 用于切换是否显示五角星
      starX: 0, // 五角星的X坐标
      starY: 0, // 五角星的Y坐标
      weatherItems: [
        {
          imageSrc:
            "https://lesson.iapeap.com/images/EmotionalValue/tianqi1.png",
          alt: "",
          text: "晴",
        },
        {
          imageSrc:
            "https://lesson.iapeap.com/images/EmotionalValue/tianqi2.png",
          alt: "",
          text: "多云",
        },
        {
          imageSrc:
            "https://lesson.iapeap.com/images/EmotionalValue/tianqi3.png",
          alt: "",
          text: "小雨",
        },
        {
          imageSrc:
            "https://lesson.iapeap.com/images/EmotionalValue/tianqi4.png",
          alt: "",
          text: "大雨",
        },
        {
          imageSrc:
            "https://lesson.iapeap.com/images/EmotionalValue/tianqi5.png",
          alt: "",
          text: "雪",
        },
      ],
      activeIndex: 0,
      weather: 0,
      comment: "",
      onlyMe: false,
      offsetX: 0,
      offsetY: 0,
      task_info: "",
      starttime: "",
      endtime: "",
      time: "",
    };
  },
  mounted() {
    if (this.$route.query.item) {
      this.id = this.$route.query.item.id;
      this.task_info = this.$route.query.item.task_info;

      console.log(this.id);
    }
    this.timer = setInterval(() => {
      this.time++;
      // console.log("页面累积浏览时间是：",this.time,"秒")
    }, 1000); // 每秒触发一次
    // ```````````获取当前的年月日时分秒，并以"2018-01-01 0:00"的格式显示
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = String(currentDate.getMonth() + 1).padStart(2, "0"); // 补0操作，确保是两位数
    const day = String(currentDate.getDate()).padStart(2, "0");
    const hours = String(currentDate.getHours()).padStart(2, "0");
    const minutes = String(currentDate.getMinutes()).padStart(2, "0");
    const seconds = String(currentDate.getSeconds()).padStart(2, "0");

    this.starttime = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    console.log("进入页面的系统时间是：", this.starttime);
  },
  beforeDestroy() {
    clearInterval(this.timer); // 清除定时器
    // ```````````获取当前的年月日时分秒，并以"2018-01-01 0:00"的格式显示
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = String(currentDate.getMonth() + 1).padStart(2, "0"); // 补0操作，确保是两位数
    const day = String(currentDate.getDate()).padStart(2, "0");
    const hours = String(currentDate.getHours()).padStart(2, "0");
    const minutes = String(currentDate.getMinutes()).padStart(2, "0");
    const seconds = String(currentDate.getSeconds()).padStart(2, "0");
    this.endttime = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    console.log("离开页面的系统时间是", this.endttime);

    //把参数传给后端接口，starttime、endttime、time
    let data = {
      startTime: this.starttime,
      endTime: this.endttime,
      userId: window.localStorage.getItem("userId"),
      pageName: "情绪日记",
      timeStamp: this.time,
    };
    memberLog(data).then((res) => {});
  },
  computed: {
    formattedText() {
      return this.task_info.replace(/\r\n/g, "<br>");
    },
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    },
    handleClick(index) {
      this.activeIndex = index;
      this.weather = index;
      console.log(this.activeIndex);
    },
    toggleStar(event) {
      const rect = this.$refs.zuobiaoBox.getBoundingClientRect();
      const offsetX = parseInt(event.clientX - rect.left);
      const offsetY = parseInt(event.clientY - rect.top);
      console.log(offsetX, offsetY);
      this.emoCoordinate = [offsetX, offsetY];
      const starSize = 14; // 五角星大小
      // 切换是否显示五角星
      this.showStar = true;
      // 更新五角星的位置
      if (this.showStar) {
        this.starX = offsetX - starSize / 2;
        this.starY = offsetY - starSize / 2;
      }
    },
    tosevenDay() {
      this.$router.push("/emotion/SevenDay");
    },

    //上传图片
    afterRead(file) {
      Toast.loading({
        duration: 0, // 持续展示 toast
        message: "上传中",
        forbidClick: true,
      });
      if (!Array.isArray(file)) {
        let data = file.file;
        uploadFile(data).then((res) => {
          if (res.msg == "图片格式错误！") {
            Toast.fail("格式错误!");
            this.fileList = [];
          } else {
            Toast.success("上传成功");
            this.imglist.push(res.imgUrl);
          }
        });
      }

      if (Array.isArray(file) && file.length > 1) {
        for (let i = 0; i < file.length; i++) {
          let data = file[i].file;
          uploadFile(data).then((res) => {
            if (res.msg == "图片格式错误！") {
              this.fileList = [];

              Toast.fail("格式错误!");
            } else {
              Toast.success("上传成功");
              this.imglist.push(res.imgUrl);
            }
          });
        }
      }

      console.log(this.imglist);
      console.log(this.fileList);
      // Toast.success("上传成功");
    },
    //提交表单
    getsaveEmoDiary() {
      console.log(
        encodeURIComponent(this.comment),
        "encodeURIComponent(this.comment)"
      );

      if (this.emoCoordinate && this.comment) {
        Toast.loading({
          duration: 0,
          message: "正在提交",
          forbidClick: true,
        });

        if (this.onlyMe == false) {
          this.onlyMe = 0;
        } else {
          this.onlyMe = 1;
        }

        // 正则表达式匹配字符串中的表情符号
        const emojiRegex = /[\uD800-\uDBFF][\uDC00-\uDFFF]/g;
        const commentWithoutEmojis = this.comment.replace(
          emojiRegex,
          function (match) {
            // 对匹配到的表情符号进行编码
            return encodeURIComponent(match);
          }
        );
        // 编码comment参数
        let data = {
          userId: window.localStorage.getItem("userId"),
          id: this.id,
          weather: this.weather || "0",
          emoCoordinate: this.emoCoordinate,
          imgUrl: this.imglist,
          onlyMe: this.onlyMe,
          comment: commentWithoutEmojis, // 使用编码后的comment参数
        };

        saveEmoDiary(data).then((res) => {
          Toast.clear();
          this.wcshow = !this.wcshow;
          this.onsetComplated();
        });
      } else {
        Toast("请填写详细内容");
      }
    },

    //点击完成
    onsetComplated() {
      let data2 = {
        id: this.id,
        userId: window.localStorage.getItem("userId"),
      };
      setComplated(data2).then((res) => {});
    },
  },
};
</script>

<style lang="less">
.van-uploader__preview-delete {
  display: none;
}
body {
  padding-top: env(safe-area-inset-top);
  margin-top: -env(safe-area-inset-top);
}
.diary {
  background-color: #f4fafe;
  min-height: 100vh;
  padding: 40px 20px;
  box-sizing: border-box;
  .weather {
    height: 149px;
    background: #ffffff;
    box-shadow: 0px 2px 8px 0px rgba(185, 184, 184, 0.5);
    border-radius: 8px;
    padding: 10px;
    box-sizing: border-box;
    .tianqi {
      display: flex;
      font-size: 7px;
      font-weight: 300;
      justify-content: center;
      color: #becee3;
      margin-top: 10px;
      .tianqiitem {
        width: 72px;
        padding: 5px 0;
        text-align: center;
        box-sizing: border-box;
        .img {
          width: 42px;
          height: 42px;
        }
      }
      .tianqiitemactive {
        border: 1px solid #55a4f0;
        border-radius: 15px;
      }
    }
  }
  .coordinates {
    background: #ffffff;
    box-shadow: 0px 2px 8px 0px rgba(185, 184, 184, 0.5);
    border-radius: 8px;
    padding: 20px;
    box-sizing: border-box;
    margin-top: 20px;
    .zuobiao {
      margin-top: 10px;
      height: 298px;
      background-image: url("https://lesson.iapeap.com/images/EmotionalValue/zuobiao.png");
      background-size: 100% 100%;
    }
    .wenxintishi {
      margin-top: 10px;
      .qingxuzuobiao {
        display: flex;
        align-items: center;
        gap: 5px;
        img {
          width: 12px;
        }
      }
      .wenxintishi-ctx2 {
        padding: 0 10px;
        font-size: 12px;
        margin-bottom: 8px;
        color: #a09c9b;
        box-sizing: border-box;
      }
    }
  }
  .jinritiyan {
    margin-top: 20px;
    background: #ffffff;
    box-shadow: 0px 2px 8px 0px rgba(185, 184, 184, 0.5);
    border-radius: 8px;
    padding: 20px;
    box-sizing: border-box;
  }
  .tiyanbianji {
    width: 100%;
    height: 168px;
    border-radius: 8px;
    border: 2px solid #becfe3;
    padding: 15px 25px;
    box-sizing: border-box;
    margin-bottom: 10px;
  }
  .shurukuang {
    width: 15px;
    height: 15px;
    position: relative;
    top: 38px;
    left: 10px;
  }
  .tianjia {
    width: 88px;
    height: 88px;
    border-radius: 8px;
    border: 1px solid #becfe3;
    line-height: 88px;
    text-align: center;
    color: #becfe3;
    font-size: 50px;
  }
  .ctx-title {
    .title-ctx {
      z-index: 100;
      position: relative;
      font-size: 18px;
      font-weight: 500;
      color: #000025;
      line-height: 25px;
    }
    .title-fk {
      width: 35px;
      height: 5px;
      background: #629ee9;
      position: relative;
      bottom: 8px;
      z-index: 99;
      transform: skew(-25deg);
    }
  }
  .diary-btn {
    margin-top: 20px;
    display: flex;
    justify-content: center;
    button {
      width: 200px;
      height: 40px;
      color: #fff;
      border: 0;
      border-radius: 20;
      background-color: #55a4f0;
      border-radius: 25px;
    }
  }
}
.wancheng {
  width: 100%;
  height: 100%;
  background: RGBA(0, 0, 0, 0.8);
  position: fixed;
  top: 0;
  display: flex;
  justify-content: center;
  z-index: 999;
  align-items: center;
  img {
    width: 100px;
    position: relative;
    bottom: 30px;
  }
  .wcbox {
    box-shadow: 0px 2px 8px 0px rgba(185, 184, 184, 0.5);
    border-radius: 5px;
    width: 264px;
    height: 250px;
    background: #fff;
    text-align: center;
    padding: 10px 20px 0px 20px;
    box-sizing: border-box;
    color: #333;
    button {
      border: 0;
      background-color: #72acf4;
      height: 36px;
      width: 164px;
      margin-top: 10px;
      border-radius: 25px;
      color: #fff;
    }
  }
  .van-uploader__preview-delete {
    display: none;
  }
}
</style>
